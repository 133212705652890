<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />

      <v-toolbar-title class="headline font-weight-bold pa-0">
        Frequently Asked Questions
      </v-toolbar-title>
    </v-app-bar>

    <div class="contents">
      <div class="mb-6">
        <v-btn
          color="primary"
          @click="
            $router.push({
              name: 'faqs.new'
            })
          "
        >
          <v-icon class="mr-1" small>{{ icons.add }}</v-icon>
          New FAQ
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div v-for="faq in faqs" :key="faq.id" class="mb-4">
        <v-card>
          <v-card-title>
            <a
              @click="
                $router.push({ name: 'faqs.details', params: { id: faq.id } })
              "
            >
              {{ faq.question }}
            </a>

            <v-spacer></v-spacer>

            <v-btn icon @click="faq.isShow = !faq.isShow">
              <v-icon
                >{{ faq.isShow ? icons.chevronUp : icons.chevronDown }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-expand-transition>
            <div v-show="faq.isShow">
              <v-divider></v-divider>
              <v-card-text>
                {{ faq.answer }}
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapState } from 'vuex'
import { mdiChevronDown, mdiChevronUp, mdiPlus } from '@mdi/js'

export default {
  name: 'FAQPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      icons: {
        add: mdiPlus,
        chevronUp: mdiChevronUp,
        chevronDown: mdiChevronDown
      }
    }
  },

  computed: {
    ...mapState({
      faqs: state => state.faq.list
    })
  },

  async created() {
    await this.$store.dispatch('faq/getFAQs')
  }
}
</script>

<style lang="scss" scoped>
.contents {
  width: 800px;

  @media (max-width: 820px) {
    width: 100%;
  }
}
</style>
