<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <router-link class="text-decoration--none" :to="{ name: 'faqs' }">
          <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
          FAQs</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="form">
      <div class="my-6">
        <v-form class="faq-form">
          <div>
            <label class="text-field-label">Question</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model="form.question"
              :error-messages="form.$getError('question')"
              :loading="loading"
            ></v-text-field>
          </div>
          <div>
            <label class="text-field-label">Answer</label>
            <v-textarea
              flat
              solo
              required
              class="mt-2"
              v-model="form.answer"
              :error-messages="form.$getError('answer')"
              hide-details="auto"
              :loading="loading"
              rows="10"
            ></v-textarea>
          </div>
          <div class="d-flex mt-6">
            <v-btn
              color="primary"
              class="px-6"
              height="40px"
              @click="faq.id ? updateFaqDetails() : addFaq()"
              :loading="form.$busy"
              >Save</v-btn
            >

            <v-spacer></v-spacer>

            <v-btn
              v-if="faq.id"
              color="error"
              class="px-6"
              height="40px"
              :loading="form.$busy"
              @click="deleteDialog = true"
              >Delete</v-btn
            >
          </div>
        </v-form>
      </div>

      <v-dialog v-model="deleteDialog" max-width="290">
        <v-card>
          <v-card-title class="headline">Confirm Delete</v-card-title>

          <v-card-text>Are you sure you want to delete this?</v-card-text>

          <v-card-actions>
            <v-btn text @click="deleteDialog = false" :loading="form.$busy"
              >Cancel</v-btn
            >

            <v-spacer></v-spacer>

            <v-btn color="red" text @click="removeFaq" :loading="form.$busy"
              >Yes, Proceed</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiArrowLeft } from '@mdi/js'
import { mapState } from 'vuex'
import Form from '../../../utils/form'

export default {
  name: 'FaqDetails',

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      loading: false,
      deleteDialog: false,
      form: new Form({
        id: '',
        question: '',
        answer: ''
      }),
      icons: {
        arrowLeft: mdiArrowLeft
      }
    }
  },

  computed: {
    ...mapState({
      faq: state => state.faq.faqDetails
    })
  },

  methods: {
    async getFAQ() {
      this.loading = true

      await this.$store.dispatch('faq/getFaqDetails', this.$route.params.id)
      this.form = new Form(this.faq)

      this.loading = false
    },

    async updateFaqDetails() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.$store
        .dispatch('faq/updateFaq', this.form.$data())
        .then(() => {
          this.form.$busy = false

          this.$store.dispatch('showSnackbar', {
            message: 'FAQ details successfully updated!',
            color: 'success'
          })

          this.$store.commit('faq/clearList')

          this.$router.replace({ name: 'faqs' })
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    addFaq() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.$store
        .dispatch('faq/storeFaq', this.form.$data())
        .then(() => {
          this.form.$busy = false

          this.$store.dispatch('showSnackbar', {
            message: 'FAQ details successfully created!',
            color: 'success'
          })

          this.$router.replace({ name: 'faqs' })
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    removeFaq() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.$store
        .dispatch('faq/deleteFaq', this.form.id)
        .then(() => {
          this.form.$busy = false

          this.$store.dispatch('showSnackbar', {
            message: 'FAQ successfully deleted!',
            color: 'success'
          })

          this.$store.commit('faq/clearList')

          this.$router.replace({ name: 'faqs' })
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    }
  },

  created() {
    if (this.$router.currentRoute.name === 'faqs.details') {
      this.getFAQ()
    }
  },

  destroyed() {
    this.$store.commit('faq/clearDetails')
  }
}
</script>

<style lang="scss" scoped>
.faq-form {
  width: 800px;

  @media (max-width: 820px) {
    width: 100%;
  }
}
</style>
